

















































































































import DialogMixins from '@/mixins/DialogMixins';
import WidgetMixins from '@/mixins/WidgetMixins';
import { IDialogAndButtonAction, IPaymentDetails, IResolveAccount } from '@/types/types';
import { loadView, loadWidget } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import moment from 'moment';
const paymentMethodModule = namespace('paymentDetails');
@Component({
  name: 'PaymentMethods',
  components: {
    ToolBar: loadWidget('widgets/ToolBar'),
    InternetConnection: loadWidget('pages/InternetConnection'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    AddPaymentMethod: loadView('pages/payments/dialogs/AddPaymentMethod'),
    SnackBar: loadWidget('widgets/SnackBar'),
  },
  filters: {
    timestamp(value: string) {
      return moment(value).format('DD/MM/YYYY');
    },
  },
})
export default class PaymentMethod extends mixins(WidgetMixins, DialogMixins) {
  @paymentMethodModule.Getter('getPaymentMethods')
  paymentMethods!: IPaymentDetails[];
  headers: Array<{ text: string; value: string }> = [
    { text: 'Mobile Money Number', value: 'mobileMoneyNumber' },
    {
      text: 'Recipient Code',
      value: 'recipientCode',
    },
    {
      text: 'Default Account',
      value: 'isDefault',
    },
    {
      text: 'Created Date',
      value: 'createdAt',
    },
    {
      text: 'Actions',
      value: 'actions',
    },
  ];

  addPayment(payload: IDialogAndButtonAction<Pick<IResolveAccount, 'account_number' | 'bank_code'>>) {
    this.$store.dispatch('paymentDetails/addPaymentMethod', payload);
  }

  created(): void {
    this.$store.dispatch('paymentDetails/paymentList');
  }
}
